<template>
  <v-container fluid>
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Category</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        List of All Category and Item Kind<br />
      </p>
    </div>

    <v-row>
      <!-- ADD CATEGORY & INQUIRY KIND -->
      <v-col cols="5" class="mt-n8">
        <v-row>
          <!-- CATEGORY & ITEM -->
          <v-col>
            <v-card class="pa-5" style="height: 205px">
              <p class="mt-n3" style="font-weight: bold">Add Item Kind<br /></p>
              <v-select
                v-model="toInsertItemKind.Category"
                :items="categories"
                item-text="Category"
                item-value="Category"
                class="mt-n3"
                label="Category"
                color="red"
                outlined
                dense
              />
              <v-text-field
                v-model="toInsertItemKind.ItemKind"
                class="mt-n3 mb-n3"
                color="red"
                label="Item kind"
                outlined
                dense
              />
              <v-divider />
              <v-btn
                @click="AddItemKind(toInsertItemKind)"
                class="mt-2"
                style="color: white"
                color="#20b2aa"
                block
                >Add <v-icon small>mdi-plus</v-icon></v-btn
              >
            </v-card>
          </v-col>
        </v-row>
        <!-- CATEGORY -->
        <v-row class="mt-n4">
          <v-col>
            <v-card class="pa-5 category">
              <v-row class="mb-n8">
                <v-col>
                  <p class="mt-n5" style="font-weight: bold">Add Category<br /></p>
                </v-col>
              </v-row>
              <v-row class="mb-n6">
                <v-col class="mr-n3" cols="9">
                  <v-text-field
                    v-model="toInsertCategory"
                    ref="category"
                    color="red"
                    label="Category"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    @click="InsertCategory(toInsertCategory)"
                    style="color: white"
                    color="#20b2aa"
                    block
                    >Add <v-icon small>mdi-plus</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-divider class="mb-n3"></v-divider>
              <v-row>
                <v-col v-if="categories.length">
                  <v-list class="list" v-scrollbar>
                    <v-list-item-group color="#df6464">
                      <v-list-item v-for="(val, i) in categories" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            ><span
                              style="color: grey; font-size: 12px"
                              v-text="i + 1"
                            />.{{ val.Category }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item></v-list-item-group
                    >
                  </v-list>
                </v-col>
                <v-col
                  v-else
                  class="text-center mt-12"
                  colspan="9"
                  style="color: red; font-weight: bold; font-size: 20px"
                  >No Record Found</v-col
                >
              </v-row>
            </v-card></v-col
          >
        </v-row>
      </v-col>
      <!-- RECORDS -->
      <v-col class="mt-n5">
        <v-text-field label="Search" v-model="searchCategory" dense block outlined />
        <v-data-table
          :items="itemKind"
          :search="searchCategory"
          :headers="headers"
          class="elevation-1 table"
        >
          <template v-slot:[`item.Action`]="{ item }">
            <v-row>
              <v-btn
                @click="DeleteItemKind(item.id)"
                color="red"
                style="color: white"
                x-small
                >Delete</v-btn
              >
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import swal from "sweetalert2";
import store from "@/store";
import other from "../../../functions/alert";
import CorrectionCategory from "../../../functions/httpRequest/ExternalConnection/CorrectionCategoryTable";
export default {
  data() {
    return {
      searchCategory: "",
      toInsertCategory: "",
      department: store.state.userinfo.Department,
      toInsertItemKind: {},
      categories: [],
      itemKind: [],
      headers: [
        { text: "Category", value: "Category" },
        { text: "Item Kind", value: "ItemKind" },
        { text: "Action", value: "Action" },
      ],
    };
  },
  created() {
    this.GetCategory();
    this.GetItemKind();
  },
  methods: {
    DeleteItemKind(id) {
      swal
        .fire({
          title: "Are you sure you want to delete?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const toUpdate = {
              id: id,
              DeletedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            CorrectionCategory.UpdateItemKind(toUpdate).then(() => {
              this.GetItemKind();
              this.$toast.success("Deleted Successfully", other.alert);
            });
          }
        });
    },
    AddItemKind(val) {
      if (Object.keys(val).length < 2) {
        this.$toast.error("Fill-up all fields", other.alert);
        return;
      }

      const toInsert = {
        ...val,
        Department: this.department,
      };

      CorrectionCategory.InsertItemKind(toInsert).then(() => {
        this.GetItemKind();
        this.toInsertItemKind = {};
        this.$toast.success("Insert Successfully", other.alert);
      });
    },
    InsertCategory(val) {
      if (!val) {
        this.$refs.category.focus();
        this.$toast.error("Input Category", other.alert);
        return;
      }
      const toInsert = {
        Category: this.toInsertCategory,
        Department: this.department,
      };
      CorrectionCategory.InsertCategory(toInsert).then(() => {
        this.GetCategory();
        this.toInsertCategory = "";
        this.$toast.success("Insert Successfully", other.alert);
      });
    },
    GetCategory() {
      CorrectionCategory.GetCategory(this.department).then((res) => {
        if (res.data.Message != "No record found") this.categories = res.data;
      });
    },
    GetItemKind() {
      CorrectionCategory.GetItemKind(this.department).then((res) => {
        if (res.data.Message != "No record found") this.itemKind = res.data;
        else this.itemKind = [];
      });
    },
  },
};
</script>

<style lang="scss">
.table {
  max-height: calc(90vh - 55px);
  min-height: calc(90vh - 267px);
  height: 10px;
}

.category {
  max-height: calc(90vh - 55px);
  min-height: calc(90vh - 479px);
  height: 10px;
}

.list {
  max-height: calc(90vh - 55px);
  min-height: calc(90vh - 589px);
  height: 10px;
}
</style>
