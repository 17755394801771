<template>
 <iframe src="https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/HrdInquiryManual.pdf" width="100%" height="1000px"></iframe>
</template>

<script>
export default {

}
</script>

<style>

</style>