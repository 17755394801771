<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADERS -->
        <div class="text-center section">
          <h2 class="h2">Add Group Recipient</h2>
        </div>
        <v-divider />
        <!-- INFORMATION -->
        <v-row class="ml-1">
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            Form
          </p>
        </v-row>
        <!-- FORM-->
        <v-row class="mt-n7">
          <v-col>
            <v-text-field
              v-model="form.GroupName"
              color="red"
              label="GroupName"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- TO -->
        <v-row class="mt-n9">
          <!-- SWITCH -->
          <v-col v-if="checkCiInfoTo" cols="1" class="mr-2 mt-n3">
            <v-switch v-model="ciTo" label="CI" color="red" />
          </v-col>
          <!-- RECIPIENT -->
          <v-col>
            <!-- MANUAL -->
            <v-text-field
              v-if="!ciTo"
              v-model="form.record.To"
              color="red"
              label="Message Recipient (To)"
              dense
              outlined
            ></v-text-field>
            <!-- CUSTOMER INFORMATION -->
            <v-select
              v-else
              v-model="ciToValue"
              :items="checkCiInfoTo"
              label="Customer Information (To)"
              color="red"
              dense
              outlined
            ></v-select>
          </v-col>
          <!-- EMAIL -->
          <v-col v-if="!ciTo">
            <v-text-field
              v-model="form.record.ToEmail"
              color="red"
              label="E-mail Address (To)"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- CC -->
        <v-row class="mt-n9">
          <!-- SWITCH -->
          <v-col cols="1" class="mr-2 mt-n3">
            <v-switch v-model="ciCc" label="CI" color="red" />
          </v-col>
          <!-- RECIPIENT -->
          <v-col>
            <v-text-field
              v-if="!ciCc"
              v-model="form.record.Cc"
              color="red"
              label="Message Recipient (Cc)"
              dense
              outlined
            ></v-text-field>
            <!-- CUSTOMER INFORMATION -->
            <v-select
              v-else
              v-model="ciCcValue"
              :items="checkCiInfoCc"
              label="Customer Information (Cc)"
              color="red"
              dense
              outlined
            ></v-select>
          </v-col>
          <!-- EMAIL -->
          <v-col v-if="!ciCc">
            <v-text-field
              v-model="form.record.CcEmail"
              color="red"
              label="E-mail Address (Cc)"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- ADDING ACTION-->
        <v-row
          :class="
            toInsert.record.length ? 'ml-0 mr-0 mt-n7 mb-5' : 'ml-0 mr-0 mt-n7'
          "
        >
          <v-btn @click="AddRecord()" color="#800080" block outlined>Add</v-btn>
        </v-row>
        <div v-if="toInsert.record.length">
          <v-divider />
          <!-- GROUP RECORD TO BE INSERTED -->
          <v-row class="mt-n3">
            <v-col>
              <!-- TABLE -->
              <v-simple-table fixed-header height="345px">
                <template v-slot:default>
                  <!-- THEAD -->
                  <thead>
                    <tr>
                      <th class="text-center">Recipient (To)</th>
                      <th class="text-center">To</th>
                      <th class="text-center">Recipient (Cc)</th>
                      <th class="text-center">Cc</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <!-- TBODY -->
                  <tbody>
                    <!-- ROW -->
                    <tr
                      class="text-center"
                      v-for="(val, i) in toInsert.record"
                      :key="i"
                    >
                      <td v-html="val.To || 'No Record'" />
                      <td v-html="val.ToEmail || 'No Record'" />
                      <td v-html="val.Cc || 'No Record'" />
                      <td v-html="val.CcEmail || 'No Record'" />
                      <!-- DELETE ROW -->
                      <td>
                        <v-btn
                          @click="toInsert.record.splice(i, 1)"
                          color="red"
                          outlined
                          small
                          dense
                          >Delete</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-divider />
          <!-- ACTION SUBMIT -->
          <v-btn
            @click="SubmitRecord()"
            v-if="toInsert.record.length"
            class="mt-2"
            color="#20b2aa"
            style="color: white"
            block
            >Submit</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import others from "../../../../functions/alert";

export default {
  data() {
    return {
      dialog: false,
      ciTo: false,
      ciCc: false,
      ciToValue: "",
      ciCcValue: "",
      form: { record: {} },
      toInsert: {
        info: {},
        record: [],
      },
      CustomerInfo: ["Planner", "Salesman", "First", "Middle"],
    };
  },
  watch: {
    ciTo(newValue) {
      if (newValue) this.ciToValue = "";
    },
    ciCc(newValue) {
      if (newValue) this.ciCcValue = "";
    },
  },

  computed: {
    checkCiInfoTo() {
      const record = this.toInsert.record;

      return this.CustomerInfo.filter((el) => {
        if (!record.length) return el;
        const check = record.findIndex((child) => child.To == el);
        return check >= 0 ? false : true;
      });
    },
    checkCiInfoCc() {
      const record = this.toInsert.record;

      return this.CustomerInfo.filter((el) => {
        if (!record.length) return el;
        const check = record.findIndex((child) => child.Cc == el);
        return check >= 0 ? false : true;
      });
    },
  },
  methods: {
    //************************************//
    //    EMIT ALL RECORD IN MAIN VUE     //
    //************************************//

    async SubmitRecord() {
      await this.$emit("UpdateGroup", { ...this.toInsert });
      this.toInsert.record = [];
      this.form.GroupName = "";
      this.$toast.success("Insert Successfully", others.alert);
      this.dialog = !this.dialog;
    },

    //****************************//
    //    ADD RECORD IN ARRAY     //
    //****************************//

    AddRecord() {
      const checkObj = (obj, key) => Object.keys(obj).includes(key);

      if (!checkObj(this.form, "GroupName"))
        return this.$toast.error("Fill-up require fields", others.alert);

      if (this.ciToValue) {
        this.form.record.To = this.ciToValue;
        this.form.record.ToEmail = this.ciToValue;
      }
      if (this.ciCcValue) {
        this.form.record.Cc = this.ciCcValue;
        this.form.record.CcEmail = this.ciCcValue;
      }
      this.toInsert.GroupName = this.form.GroupName;
      this.$set(this.toInsert.record, this.toInsert.record.length, {
        ...this.form.record,
      });

      this.ciToValue = "";
      this.ciCcValue = "";
      this.ciTo = false;
      this.ciCc = false;
      this.form.record = {};
      this.$toast.info("Added", others.alert);
    },
    //***************************************//
    //    GET REFS EVENT FROM AddGroup.VUE   //
    //***************************************//
    openDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>