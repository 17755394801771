<template>
  <div>
    <v-dialog v-model="dialog" max-width="620px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADERS -->
        <div class="text-center section">
          <h2 class="h2">To</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            List of {{ EmployeeFirstName }} recipient
          </p>
        </div>
        <v-divider />
        <!-- RECORDS -->
        <v-row class="pl-3" v-if="record.To.length">
          <v-text-field
            v-model="searchTo" 
            prepend-inner-icon="mdi-magnify"
            label="Search E-mail"
            outlined 
            dense 
            block/> 
          <v-simple-table fixed-header height="545px" style="width: 580px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Message Recipient</th>
                  <th class="text-center">E-Mail Address</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="searchInListOfTo.length">
                <tr v-for="(val, index) in searchInListOfTo" :key="index">
                  <td class="text-center" v-html="val.To" />
                  <td class="text-center" v-html="val.Email" />
                  <td>
                    <v-btn
                      @click="
                        OpenUpdateDialog(
                          { To: val.To, Email: val.Email },
                          index
                        )
                      "
                      class="mr-1"
                      color="info"
                      x-small
                      outlined
                      >Edit</v-btn
                    >
                    <v-btn
                      color="red"
                      x-small
                      outlined
                      @click="DeleteAddress(index)"
                      >Delete</v-btn
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                  class="text-center"
                  colspan="9"
                  v-html="'No Record Found'"
                  style="color: red; font-weight: bold; font-size: 20px"
                />
              </tr>
            </tbody>
            </template>
          </v-simple-table>
        </v-row>
        <!-- FOR NO RECORD -->
        <v-row v-else>
          <v-col
            class="text-center"
            style="color: red; font-weight: bold; font-size: 20px"
            v-html="'No Record Found'"
          />
        </v-row>
        <v-divider class="mb-3" />
        <!-- ACTION -->
        <v-btn
          @click="addingDialog = !addingDialog"
          style="color: white"
          color="#20b2aa"
          block
          >Add Recipient <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card>
    </v-dialog>

    <!-- INSERT DIALOG -->
    <v-dialog
      v-model="addingDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">Addresses</h2>
          <p
            class="text-lg font-medium text-gray-600 mb-6"
            style="color: grey"
          ></p>
        </div>
        <v-divider />
        <!-- FORM -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.To"
              label="Message Recipient"
              color="red"
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.Email"
              label="E-Mail Address"
              color="red"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <!-- ACTION -->
        <v-btn
          @click.native="AddRecord()"
          class="mt-n3"
          color="#20b2aa"
          style="color: white"
          block
          >Insert</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- EDIT DIALOG -->
    <v-dialog
      v-model="editDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">Update Addresses</h2>
          <p
            class="text-lg font-medium text-gray-600 mb-6"
            style="color: grey"
          ></p>
        </div>
        <v-divider />
        <!-- FORM -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="toUpdate.To"
              label="Message Recipient"
              color="red"
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="toUpdate.Email"
              label="E-Mail Address"
              color="red"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <!-- ACTION -->
        <v-btn
          @click.native="UpdateRecord()"
          class="mt-n3"
          color="#2196f3"
          style="color: white"
          block
          >Update</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CorrectionUserTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import others from "../../../functions/alert";

export default {
  data() {
    return {
      dialog: false,
      addingDialog: false,
      editDialog: false,
      searchTo:"",
      form: {},
      toUpdate: {},
      record: [],
    };
  },
  props: {
    records: {
      type: Object,
    },
  },
  created() {
    this.record.To = [];
  },
  computed: {
    //****************************//
    //  GET EMPLOYEE FIRST NAME   //
    //****************************//
searchInListOfTo(){
   if (this.searchTo) {
        //if search ay may katulad sa list?
        return this.record.To.filter((element) => {
          return element.To.toUpperCase().includes(this.searchTo.toUpperCase()) ||
          element.Email.toUpperCase().includes(this.searchTo.toUpperCase()); //finifilter nya yung element na may katulad na letter sa search
        });
      } else {
        return this.record.To; //kung mag false ang condition
      }
    },
    EmployeeFirstName() {
      // CHECK IF KEY IS EXIST
      let fullname =
        Object.keys(this.record).length > 1
          ? this.record.EmployeeName.toLowerCase().split(" ").splice(0, 1)
          : [];
      // UPPERCASE FIRST LETTER EVERY WORD
      for (let x in fullname)
        fullname[x] = `${fullname[x].charAt(0).toUpperCase()}${fullname[
          x
        ].substring(1)}`;
      return `${fullname.join(" ")}'s`;
    },
  },
  methods: {
    //****************************//
    //      UPDATE ADDRESS        //
    //****************************//

    UpdateRecord() {
      // GET INDEX
      const index = this.toUpdate.index;

      // PASS UPDATE IN MAIN RECORD
      for (let x in this.toUpdate)
        if (x != "index") this.$set(this.record.To[index], x, this.toUpdate[x]);

      // DATA TO BE UPDATE
      const toUpdate = {
        To: JSON.stringify(this.record.To),
        EmployeeCode: this.record.EmployeeCode,
      };

      // UPDATE DATABASE
      CorrectionUserTable.Update(toUpdate).then(() => {
        delete this.toUpdate.To;
        delete this.toUpdate.Email;
        this.editDialog = false;
        this.$toast.success("Updated Successfully", others.alert);
      });
    },

    //****************************//
    //     OPEN UPDATE MODAL      //
    //****************************//

    OpenUpdateDialog(val, index) {
      this.toUpdate = { ...val, index: index };
      this.editDialog = true;
    },
    //******************************//
    //     DELETE PER USER TO       //
    //******************************//

    DeleteAddress(index) {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to delete Address",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // DELETE AND EMIT RECORD
            this.record.To.splice(index, 1);
            this.$emit(
              "UpdateTo",
              this.record.To.length ? JSON.stringify(this.record.To) : null,
              this.records.index
            );

            // DATA TO BE UPDATED
            const toUpdate = {
              To: this.record.To.length ? JSON.stringify(this.record.To) : null,
              EmployeeCode: this.record.EmployeeCode,
            };

            // UPDATE DATABASE
            CorrectionUserTable.Update(toUpdate).then(() =>
              this.$toast.success("Deleted Successfully", others.alert)
            );
          }
        },
      });
    },

    //******************************//
    //       ADD PER USER TO        //
    //******************************//

    AddRecord() {
      // CHECK FOR OBJECT KEYS IF EXIST
      const checkObj = (obj, key) => Object.keys(obj).includes(key);

      // CHECK IF ALL FIELDS IS FILL-UP
      if (!checkObj(this.form, "To") || !checkObj(this.form, "Email"))
        return this.$toast.error("Fill-up all fields", others.alert);
      // UPDATE RECORD
      this.record.To.push({ ...this.form });

      // RECORD TO BE PASS IN DATABASE
      const toUpdate = {
        To: JSON.stringify(this.record.To),
        EmployeeCode: this.record.EmployeeCode,
      };

      // UPDATE DATABASE
      CorrectionUserTable.Update(toUpdate).then(() => {
        // EMIT AND EMPTY FORM
        this.$emit(
          "UpdateTo",
          JSON.stringify(this.record.To),
          this.records.index
        );
        for (let x in this.form) {
          this.form[x] = "";
          delete this.form[x];
        }

        this.$toast.success("Added Successfully", others.alert);
      });
    },

    //****************************//
    //        OPEN DIALOG         //
    //****************************//

    OpenDialog() {
      this.dialog = !this.dialog;
    },

    //******************************************//
    //   PASS RECORD AFTER USER ONCLICK EVENT   //
    //******************************************//

    PassRecord() {
      this.record = { ...(this.records.record || "") };
      this.record.To = this.record.To ? JSON.parse(this.record.To) : [];
    },
  },
};
</script>

