<template>
  <v-container fluid>
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Correction Request Users</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        Information and access rights of user<br />
      </p>
    </div>
    <!-- SEARCH -->
    <v-row class="mb-n12">
      <v-col cols="2"
        ><v-text-field
          v-model="search"
          label="Search"
          style="width: 200px"
          color="red"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-tooltip bottom nudge-left="65" nudge-top="40">
        <template v-slot:activator="{ on, attrs }">
          <v-col v-bind="attrs" v-on="on" cols="2">
            <span
              ><v-switch
                v-model="addTemp"
                style="margin-top: 2px"
                color="#df6464"
              ></v-switch
            ></span>
          </v-col>
        </template>
        <span>Add Template</span>
      </v-tooltip>
      <v-col v-if="addTemp == true" cols="6">
        <v-btn
          @click="OpenJobKind(bulkSelectEmp)"
          class="mr-3"
          color="#21ccbb"
          small
          outlined
          >Job</v-btn
        >
        <v-btn
          @click="OpenInquiryKind(bulkSelectEmp)"
          class="mr-3"
          color="#ffaabb"
          small
          outlined
          >Inquiry</v-btn
        >
        <!--  <v-btn
                          @click="OpenTo(val, index)"
                          class="mr-3"
                          color="#f7347a"
                          small
                          outlined
                        >
                          To</v-btn
                        >
                        <v-btn
                          @click="OpenGroup(val, index)"
                          class="mr-3"
                          color="#800080"
                          small
                          outlined
                        >
                          Group</v-btn
                        >
                        <v-btn
                          @click="OpenTemplate(val, index)"
                          color="#ff5511"
                          small
                          outlined
                        >
                          Template</v-btn
                        > -->
      </v-col>
      <v-col else></v-col>
    </v-row>

    <!-- ALL RECORDS -->
    <!-- {{bulkSelectEmp}} -->
    <v-row>
      <v-col>
        <v-simple-table fixed-header height="529px">
          <template v-slot:default>
            <!-- THEAD -->
            <thead>
              <tr>
                <th v-if="addTemp == true"></th>
                <th
                  class="text-left"
                  v-for="(val, index) in header"
                  :key="index"
                  v-html="val"
                />
                <th class="text-center"></th>
              </tr>
            </thead>
            <!-- TBODY -->
            <tbody v-if="filterUsers.length">
              <tr v-for="(val, index) in filterUsers" :key="index">
                <td v-if="addTemp == true">
                  <input v-model="bulkSelectEmp" :value="val" type="checkbox" />
                </td>
                <td else></td>
                <td>
                  <v-avatar>
                    <img :src="GetPicture(val.EmployeeCode)" @error="changeImage" />
                  </v-avatar>
                </td>
                <td v-html="val.EmployeeCode"></td>
                <td v-html="val.EmployeeName"></td>
                <td
                  @click="EmailDialog(val, index)"
                  v-html="val.Email || 'No Record Found'"
                  :style="val.Email ? 'cursor: pointer' : 'color:red;cursor: pointer'"
                ></td>
                <td v-html="val.Department"></td>
                <td v-html="val.Section"></td>
                <td v-html="val.Team || '-'"></td>
                <td v-html="val.Position"></td>
                <td>
                  <!-- ACTION -->
                  <v-treeview :items="items" open-on-click transition dense>
                    <template v-slot:prepend="{ item }">
                      <div v-if="!item.children">
                        <v-btn
                          @click="OpenJobKind(val, index)"
                          class="mr-3"
                          color="#21ccbb"
                          small
                          outlined
                          >Job</v-btn
                        >
                        <v-btn
                          @click="OpenInquiryKind(val, index)"
                          class="mr-3"
                          color="#ffaabb"
                          small
                          outlined
                          >Inquiry</v-btn
                        >
                        <v-btn
                          @click="OpenTo(val, index)"
                          class="mr-3"
                          color="#f7347a"
                          small
                          outlined
                        >
                          To</v-btn
                        >
                        <v-btn
                          @click="OpenGroup(val, index)"
                          class="mr-3"
                          color="#800080"
                          small
                          outlined
                        >
                          Group</v-btn
                        >
                        <v-btn
                          @click="OpenTemplate(val, index)"
                          color="#ff5511"
                          small
                          outlined
                        >
                          Template</v-btn
                        >
                      </div>
                    </template>
                  </v-treeview>
                </td>
              </tr>
            </tbody>
            <!-- FOR NO RECORD -->
            <tbody v-else>
              <tr>
                <td
                  class="text-center"
                  colspan="9"
                  v-html="'No Record Found'"
                  style="color: red; font-weight: bold; font-size: 20px"
                />
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <!-- UPDATE EMAIL DIALOG -->
    <v-dialog
      v-model="updateEmailDialog"
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-text-field
          v-model="updateEmailInfo.Email"
          color="red"
          label="Email"
          dense
          outlined
        ></v-text-field>
        <v-btn
          @click="UpdateEmail(updateEmailInfo)"
          class="mt-n2"
          color="success"
          block
          outlined
          >Update</v-btn
        >
      </v-card>
    </v-dialog>

    <!---------------- 
    **  COMPONENTS  **
    ----------------->

    <!-- JOBKIND COMPONENT -->
    <JobKind
      ref="jobkind"
      @UpdateJobkind="UpdateJobkind"
      :records="forComponents"
      :bulkSelectEmp="bulkSelectEmp"
    />
    <!-- INQUIRYKIND COMPONENT -->
    <InquiryKind
      ref="Inquirykind"
      @UpdateInquirykind="UpdateInquirykind"
      :records="forComponents"
    />
    <!-- TO  COMPONENT-->
    <To ref="To" @UpdateTo="UpdateTo" :records="forComponents" />
    <!-- GROUP  COMPONENT-->
    <Group ref="group" @UpdateGroup="UpdateGroup" :records="forComponents" />
    <!-- TEMPLATE  COMPONENT-->
    <Template ref="template" @UpdateTemplate="UpdateTemplate" :records="forComponents" />
  </v-container>
</template>
<script>
import store from "@/store";
import JobKind from "./JobKind.vue";
import InquiryKind from "./Inquiry.vue";
import To from "./To.vue";
import Template from "./Template.vue";
import Group from "./Group/Group.vue";
import CorrectionTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import Others from "../../../functions/alert";

export default {
  data() {
    return {
      bulkSelectEmp: [],
      addTemp: false,
      updateEmailDialog: false,
      userInformation: store.state.userinfo,
      search: "",
      updateEmailInfo: {},
      forComponents: {},
      Users: [],
      header1: [
        "",
        "",
        "",
        "EmployeeCode",
        "EmployeeName",
        "Email",
        "Department",
        "Section",
        "Team",
        "Position",
      ],
      header: [
        "",
        "",
        "EmployeeCode",
        "EmployeeName",
        "Email",
        "Department",
        "Section",
        "Team",
        "Position",
        "View Template Setting",
      ],
    };
  },
  components: {
    JobKind: JobKind,
    InquiryKind: InquiryKind,
    To: To,
    Template: Template,
    Group: Group,
  },
  created() {
    this.GetUsers();
  },
  computed: {
    items() {
      return [
        {
          name: "Action",
          children: [{ id: 1, name: "" }],
        },
      ];
    },
    //************************//
    //  FILTER TABLE RECORDS  //
    //************************//

    filterUsers() {
      // CHECK IF USER TYPE NUMBER OR LETTER
      const isNumber = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);

      return this.Users.filter((parent) =>
        parent[isNumber(this.search) ? "EmployeeCode" : "EmployeeName"]
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    //********************************//
    //  OPEN DIALGO AND PASS RECORD   //
    //********************************//
    selectedEmp(val) {
      let newbulkSelectEmp = [];
      newbulkSelectEmp.push(val);
      console.log(newbulkSelectEmp);
    },
    EmailDialog(info, index) {
      this.updateEmailInfo.Email = info.Email;
      this.updateEmailInfo.EmployeeCode = info.EmployeeCode;
      this.updateEmailInfo.index = index;
      this.updateEmailDialog = true;
    },

    //********************************//
    //       UPDATE USER EMAIL        //
    //********************************//

    async UpdateEmail(val) {
      val.Email = val.Email || null;
      this.$set(this.Users[val.index], "Email", val.Email);
      delete val.index;

      await CorrectionTable.Update(val).then(() => {
        this.updateEmailDialog = false;
        this.$toast.success("Update Successfully", Others.alert);
      });
    },
    //********************************//
    //  EMIT EVENT FROM JOBKIND.VUE   //
    //********************************//

    UpdateJobkind(jobkind, index) {
      // UPDATE USER JOB
      console.log(jobkind, index);
      // this.$set(this.Users[index], "Job", jobkind);
    },
    //***********************************//
    //  EMIT EVENT FROM INQUIRYKIND.VUE  //
    //***********************************//

    UpdateInquirykind(inquirykind, index) {
      // UPDATE USER INQUIRY
      this.$set(this.Users[index], "Inquiry", inquirykind);
    },
    //********************************//
    //    EMIT EVENT FROM To.VUE      //
    //********************************//

    UpdateTo(To, index) {
      // UPDATE USER To
      this.$set(this.Users[index], "To", To);
    },
    //********************************//
    //    EMIT EVENT FROM Group.VUE   //
    //********************************//

    UpdateGroup(Group, index) {
      // UPDATE USER Group
      this.$set(this.Users[index], "Group", Group);
    },
    //********************************//
    //    EMIT EVENT FROM Template.VUE   //
    //********************************//

    UpdateTemplate(Template, index) {
      // UPDATE USER Group
      this.$set(this.Users[index], "Template", Template);
    },
    //************************************//
    //   GET REFS EVENT FROM JOBKIND.VUE  //
    //************************************//

    OpenJobKind(val, index) {
      console.log(Array.isArray(val));
      if (Array.isArray(val)) {
        console.log(val);
        this.$refs.jobkind.OpenDialog();
        this.$refs.jobkind.PassRecord();
      } else {
        this.forComponents.record = val;
        this.forComponents.index = index;
        this.$refs.jobkind.OpenDialog();
        this.$refs.jobkind.PassRecord();
      }
    },
    //************************************//
    //   GET REFS EVENT FROM INQUIRYKIND.VUE  //
    //************************************//

    OpenInquiryKind(val, index) {
      if (Array.isArray(val)) {
        console.log(val);
        this.$refs.Inquirykind.OpenDialog();
        this.$refs.Inquirykind.PassRecord();
      } else {
        this.forComponents.record = val;
        this.forComponents.index = index;
        this.$refs.Inquirykind.OpenDialog();
        this.$refs.Inquirykind.PassRecord();
      }
    },
    //************************************//
    //    GET REFS EVENT FROM To.VUE      //
    //************************************//

    OpenTo(val, index) {
      this.forComponents.record = val;
      this.forComponents.index = index;
      this.$refs.To.PassRecord();
      this.$refs.To.OpenDialog();
    },
    //************************************//
    //    GET REFS EVENT FROM Group.VUE   //
    //************************************//

    OpenGroup(val, index) {
      this.forComponents.record = val;
      this.forComponents.index = index;
      this.$refs.group.PassRecord();
      this.$refs.group.OpenDialog();
    },
    //************************************//
    //    GET REFS EVENT FROM Template.VUE   //
    //************************************//
    OpenTemplate(val, index) {
      this.forComponents.record = val;
      this.forComponents.index = index;
      this.$refs.template.PassRecord();
      this.$refs.template.OpenDialog();
    },

    //********************//
    //   GET ALL USER     //
    //********************//

    GetUsers() {
      CorrectionTable.Get().then((res) => {
        let filterUser = res.data.filter((check) => {
          if (
            check.Department == this.userInformation.Department &&
            check.Section == this.userInformation.Section
          ) {
            return check;
          } else if (
            this.userInformation.Position == "Supervisor" &&
            check.Department == this.userInformation.Department
          ) {
            return check;
          } else if (this.userInformation.EmployeeCode == "14574") {
            return (
              check.Department == "CAD Planning 1" ||
              check.Department == "CAD Planning 2" ||
              check.Department == "CAD Planning 3"
            );
          }
          // check.Department == this.userInformation.Department &&
          //   check.Section == this.userInformation.Section;
        });
        this.Users = Object.assign(filterUser);
        // console.log(filterUser);
      });
    },

    //********************//
    //  EMPLOYEE PICTURE  //
    //********************//

    GetPicture(code) {
      return Others.EmployeePicture.Picture(code);
    },

    //**********************//
    //   NO AVAILABLE SRC   //
    //**********************//

    changeImage(event) {
      event.target.src = require("../../../assets/no-image.png");
    },
  },
};
</script>
<style>
.cbox {
  transform: scale(80%);
}
</style>
