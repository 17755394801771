<template>
  <div>
    <v-dialog v-model="dialog" max-width="620px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADERS -->
        <div class="text-center section">
          <h2 class="h2">Template</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            Set Employee Template
          </p>
        </div>
        <v-divider />
        <!-- RECORDS -->
        <v-row>
          <v-col>
             <v-text-field
            v-model="searchTemplate" 
            prepend-inner-icon="mdi-magnify"
            label="Search Template"
            outlined 
            dense 
            block 
            />
            <v-simple-table fixed-header height="545px">
              <template v-slot:default>
                <!-- THEAD -->
                <thead>
                  <tr>
                    <th class="text-center">Job Kind</th>
                    <th class="text-center">Inquiry Kind</th>
                    <th class="text-center">Title</th>
                    <th class="text-center">Template</th>
                  </tr>
                </thead>
                <!-- TBODY -->
                <tbody v-if="searchInListOfTemplate.length">
                  <tr
                    @dblclick="DeleteTemplate(val, index)"
                    v-for="(val, index) in searchInListOfTemplate"
                    :key="index"
                    style="cursor: pointer"
                  >
                    <td class="text-center" v-text="val.InquiryKindID" />
                    <td class="text-center" v-text="val.JobKindID" />
                    <td class="text-center" v-text="val.Title" />
                    <td class="text-center" v-html="val.Template" />
                  </tr>
                </tbody>
                <!-- FOR NO RECORD -->
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="9"
                      v-html="'No Record Found'"
                      style="color: red; font-weight: bold; font-size: 20px"
                    />
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-divider class="mb-3" />
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to delete template</span
        >
        <!-- ACTION -->
        <v-btn
          @click="addDialog = !addDialog"
          style="color: white"
          color="#20b2aa"
          block
          >Add Template <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card>
    </v-dialog>
    <!-- FOR INSERTING -->
    <v-dialog
      v-model="addDialog"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">Templates</h2>
          <p
            class="text-lg font-medium text-gray-600 mb-6"
            style="color: grey"
          ></p>
        </div>
        <v-divider />
        <v-row>
          <v-col>
            <v-autocomplete
              @change="getTemplate(info.jobkind, info.inquirykind)"
              v-model="info.jobkind"
              :items="jobkinds"
              item-value="Code"
              item-text="template"
              label="Job Kind"
              color="red"
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-autocomplete
              @change="getTemplate(info.jobkind, info.inquirykind)"
              v-model="info.inquirykind"
              :items="inquiryKind"
              item-value="Code"
              item-text="template"
              label="Inquiry Kind"
              color="red"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table fixed-header height="545px">
              <template v-slot:default>
                <!-- THEAD -->
                <thead>
                  <tr>
                    <th class="text-center">Title</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <!-- TBODY -->
                <tbody v-if="templates.length">
                  <tr
                    @dblclick="AddTemplate(val)"
                    v-for="(val, index) in templates"
                    :key="index"
                    style="cursor: pointer"
                  >
                    <td class="text-center" v-text="val.Title" />
                    <td class="text-center" v-html="val.Template" />
                  </tr>
                </tbody>
                <!-- FOR NO RECORD -->
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="9"
                      v-html="'No Record Found'"
                      style="color: red; font-weight: bold; font-size: 20px"
                    />
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-divider />
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to add Template</span
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import jobKindTable from "../../../functions/httpRequest/ExternalConnection/JobKindTable";
import inquiryKindTable from "../../../functions/httpRequest/ExternalConnection/InquiryKindTable";
import TemplateTable from "../../../functions/httpRequest/ExternalConnection/TemplateTable";
import CorrectionUser from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import others from "../../../functions/alert";

export default {
  data() {
    return {
      searchTemplate:"",
      dialog: false,
      addDialog: false,
      department: store.state.userinfo.Department,
      info: {},
      record: {},
      jobkinds: [],
      inquiryKind: [],
      templates: [],
      recordTemplate: [],
      toInsert:[],
      deletedTemplate:{},
    };
  },
  computed:{
searchInListOfTemplate(){
   if (this.searchTemplate) {
        //if search ay may katulad sa list?
        return this.recordTemplate.filter((element) => {
          return element.InquiryKindID.toUpperCase().includes(this.searchTemplate.toUpperCase()) ||
          element.JobKindID.toUpperCase().includes(this.searchTemplate.toUpperCase()) ||
          element.Title.toUpperCase().includes(this.searchTemplate.toUpperCase()) ||
          element.Template.toUpperCase().includes(this.searchTemplate.toUpperCase());         
          });
      } else {
        return this.recordTemplate; //kung mag false ang condition
      }
    },
  },
  props: {
    records: {
      type: Object,
    },
  },
  watch: {
    addDialog(newValue) {
      if (newValue) {
        this.GetJobKind();
        this.GetInquiryKind();
      } else {
        this.info = {};
        this.templates = [];
      }
    },
  },
  methods: {
    DeleteTemplate(val){
    // console.log(val)
           this.$confirm({
        title: "Confirm",
        message: "Confirm to delete Template?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // CONVERT JOB TO STRING
            // console.log(this.recordTemplate)
            this.recordTemplate.filter((element)=>{
              // console.log(val)
        if(element.id == val.id){
          // console.log(element)
          let index = this.recordTemplate.indexOf(element)
          // console.log(index)
         this.deletedTemplate = this.recordTemplate.splice(index,1)
         this.$toast.success("Deleted Successfully", others.alert);
      TemplateTable.Delete(val).then(() => {
        });
        }
      // console.log()
      });
      
        
            // UPDATE DATABASE
            // TemplateTable.Delete(record).then(() => {
            //   this.$emit("UpdateTemplate", record.Template, this.records.index); //UPDATE USERACCOUNT JOB
            //   this.$toast.success("Deleted Successfully", others.alert);
            // });
          }
        },
      });
    },
    //*********************************//
    //       ADD USER TEMPLATE         //
    //*********************************//

    AddTemplate(val) {
      this.record.Template.push(val.id);

      const toUpdate = {
        Template: JSON.stringify(this.record.Template),
        EmployeeCode: this.record.EmployeeCode,
      };

      CorrectionUser.Update(toUpdate).then(async () => {
        this.$emit("UpdateTemplate", toUpdate.Template, this.records.index);
        this.GetUserTemplate();
        this.getTemplate(this.info.jobkind, this.info.inquirykind);
        this.$toast.success("Added Successfully", others.alert);
      });
    },
    //*********************************//
    //         GET TEMPLATE            //
    //*********************************//

    getTemplate(jobKind = null, inquiryKind = null) {
      if (jobKind && inquiryKind) {
        const query = {
          InquiryKindID: inquiryKind,
          JobKindID: jobKind,
          Department: this.department,
        };

        TemplateTable.GetSpecific(query).then((res) => {
          this.templates = res.data.filter(
            (el) => !this.record.Template.includes(el.id)
          );
        });
      } else this.templates = [];
    },
    //*********************************//
    //       GET USER TEMPLATE         //
    //*********************************//
    GetUserTemplate() {
      TemplateTable.GetUser({
        template: this.record.Template.join("','"),
      }).then((res) => {
        let viewTempalte = res.data.filter((rec)=>{
          if(!rec.deletedDate){
            return rec
          }
        })
        this.recordTemplate = viewTempalte;
      });
    },
    //*********************************//
    //         GET JOB KIND            //
    //*********************************//

    GetJobKind() {
      jobKindTable.Get().then((res) => {
        for (let x of res.data)
          x.template = `${x.Code} - ( ${x.EnglishName} / ${x.JapaneseName} )`;
        res.data.unshift({ Code: "", template: "All" });
        this.jobkinds = res.data;
      });
    },

    //*********************************//
    //        GET INQUIRY KIND         //
    //*********************************//

    GetInquiryKind() {
      inquiryKindTable.Get().then((res) => {
        for (let x of res.data)
          x.template = `${x.Code} - ( ${x.EnglishName} / ${x.JapaneseName} )`;
        res.data.unshift({ Code: "", template: "All" });
        this.inquiryKind = res.data;
      });
    },

    //****************************//
    //        OPEN DIALOG         //
    //****************************//

    OpenDialog() {
      this.dialog = !this.dialog;
    },
    //******************************************//
    //   PASS RECORD AFTER USER ONCLICK EVENT   //
    //******************************************//

    PassRecord() {
      this.record = { ...(this.records.record || "") };
      this.record.Template = this.record.Template
        ? JSON.parse(this.record.Template)
        : [];
      this.GetUserTemplate();
    },
  },
};
</script>