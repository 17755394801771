<template>
  <div>
    <!-- LIST OF PER EMPLOYEE JOBKIND -->
    <v-dialog v-model="dialog" max-width="600px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">JobKind</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            List of {{ EmployeeFirstName }} JobKind
          </p>
        </div>
        <v-divider />
        <!-- RECORDS -->
        <v-row v-if="record.Job" style="height: 500px" v-scrollbar>
          <v-col>
            <v-text-field
              v-model="searchJobKind"
              prepend-inner-icon="mdi-magnify"
              label="Search Job Kind"
              outlined
              dense
              block
            />
            <v-list v-if="searchInListOfJobKind.length">
              <v-list-item-group color="#df6464" width="100%">
                <v-list-item v-for="(val, index) in searchInListOfJobKind" :key="index">
                  <v-list-item-content @dblclick="DeleteJobKind(val.Code)">
                    <v-list-item-title>
                      {{ val.Code }} - ( {{ val.EnglishName }} / {{ val.JapaneseName }} )
                    </v-list-item-title>
                    <v-divider class="mt-2" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-list v-else>
              <center><b style="color: red; float: center">NO RECORD FOUND!</b></center>
            </v-list>
          </v-col>
        </v-row>
        <!-- FOR NO RECORD -->
        <v-row v-else>
          <v-col
            class="text-center"
            style="color: red; font-weight: bold; font-size: 20px"
            v-html="'No Record Found'"
          />
        </v-row>
        <v-divider />
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to delete jobkind</span
        >
        <!-- ACTION -->
        <v-btn
          @click="addingDialog = !addingDialog"
          style="color: white"
          color="#20b2aa"
          block
          >Add Jobkind <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card>
    </v-dialog>

    <!-- ALL THE LIST OF SYSTEM JOBKIND && ADDING OF JOBKIND -->
    <v-dialog v-model="addingDialog" max-width="600px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-row>
          <v-col>
            <!-- HEADER -->
            <div class="text-center section">
              <h2 class="h2">Select Jobkind</h2>
            </div></v-col
          >
        </v-row>
        <!-- SEARCH JOBKIND -->
        <v-row class="mb-n5">
          <v-col>
            <v-text-field
              v-model="searchCode"
              label="Job Code"
              color="red"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="searchKind"
              label="JobKind"
              color="red"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <div v-if="filterJobKind.length" style="height: 500px" v-scrollbar>
          <v-row class="ma-n3">
            <v-list>
              <v-list-item-group color="#df6464" style="">
                <v-list-item
                  v-for="(val, index) in filterJobKind"
                  :key="index"
                  style="width: 600px"
                >
                  <v-list-item-content @dblclick="ConfirmInformation(val.Code)">
                    <v-list-item-title>
                      {{ val.Code }} - ( {{ val.EnglishName }} / {{ val.JapaneseName }} )
                    </v-list-item-title>
                    <v-divider class="mt-2" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </div>
        <!-- FOR NO RECORD -->
        <div v-else>
          <v-row>
            <v-col
              class="text-center"
              colspan="9"
              v-html="'No Record Found'"
              style="color: red; font-weight: bold; font-size: 20px"
            />
          </v-row>
        </div>
        <v-divider></v-divider>
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to add jobkind</span
        >
        <!-- ACTION -->
        <v-btn
          @click="AddRecord()"
          class="mb-n2 mt-3"
          color="#20b2aa"
          style="color: white"
          block
          >Insert</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import JobKindTable from "../../../functions/httpRequest/ExternalConnection/JobKindTable";
import CorrectionUserTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import objects from "../../../functions/alert";

export default {
  data() {
    return {
      dialog: false,
      addingDialog: false,
      searchCode: "",
      searchKind: "",
      searchJobKind: "",
      record: {},
      search: {},
      jobKind: [],
      toInsert: [],
    };
  },
  props: {
    records: {
      type: Object,
    },
    bulkSelectEmp: {
      type: Array,
    },
  },
  created() {
    this.GetJobKind();
  },

  computed: {
    //****************************//
    //  GET EMPLOYEE FIRST NAME   //
    //****************************//
    searchInListOfJobKind() {
      if (this.searchJobKind) {
        //if search ay may katulad sa list?
        return this.userJobkind.filter((element) => {
          return element.Code.toUpperCase().includes(this.searchJobKind.toUpperCase()); //finifilter nya yung element na may katulad na letter sa search
        });
      } else {
        return this.userJobkind; //kung mag false ang condition
      }
    },

    EmployeeFirstName() {
      // CHECK IF KEY IS EXIST
      let fullname = Object.keys(this.record).length
        ? this.record.EmployeeName.toLowerCase().split(" ").splice(0, 1)
        : [];
      // UPPERCASE FIRST LETTER EVERY WORD
      for (let x in fullname)
        fullname[x] = `${fullname[x].charAt(0).toUpperCase()}${fullname[x].substring(1)}`;

      return `${fullname.join(" ")}'s`;
    },

    //******************************//
    //  SEARCH AND FILTER JOBKIND   //
    //******************************//

    filterJobKind() {
      // CHECK IF USER SEARCH SOMETHING
      if (this.searchCode || this.searchKind) {
        let type;
        let search;
        if (this.searchCode || this.searchJobKind) {
          type = "Code";
          search = "searchCode";
        } else {
          type = "JapaneseName";
          search = "searchKind";
        }

        return this.jobKind.filter((el) =>
          el[type].toLowerCase().includes(this[search].toLowerCase())
        );
      } else return this.jobKind; //RETURN ALL DATA
    },

    //******************************//
    //  GET ALL PER USER JOBKIND    //
    //******************************//

    userJobkind() {
      const userJobkind = Object.keys(this.record).length
        ? JSON.parse(this.record.Job)
        : [];
      const filterJobKind = this.jobKind
        .filter((el) => {
          const check = userJobkind.indexOf(el.Code);
          return check === -1 ? false : true;
        })
        .map((el) => el);
      return filterJobKind;
    },
  },
  methods: {
    //******************************//
    //   DELETE PER USER JOBKIND    //
    //******************************//

    DeleteJobKind(val) {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to delete jobkind",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // CONVERT JOB TO STRING
            this.record.Job = JSON.stringify(
              JSON.parse(this.record.Job).filter((code) => code.indexOf(val))
            );
            // CHECK IF TOINSERT IS EMPTY
            this.toInsert = this.toInsert.filter((code) => code.indexOf(val));
            if (this.record.Job == "[]") this.record.Job = null;

            // RECORD TO BE PASS IN DATABASE
            const record = {
              Job: this.record.Job == "[]" ? null : this.record.Job,
              EmployeeCode: this.record.EmployeeCode,
            };
            // UPDATE DATABASE
            CorrectionUserTable.Update(record).then(() => {
              this.$emit("UpdateJobkind", record.Job, this.records.index); //UPDATE USERACCOUNT JOB
              this.$toast.success("Deleted Successfully", objects.alert);
            });
          }
        },
      });
    },

    //******************************//
    //   ADDING PER USER JOBKIND    //
    //******************************//

    ConfirmInformation(Code) {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to add jobkind",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // CHECK IF JOBKIND IS ALREADY EXIST
            if (this.toInsert.indexOf(Code) != -1)
              return this.$toast.error("Jobkind already exist", objects.alert);
            this.toInsert.push(Code);
            this.$toast.info("Added", objects.alert);
          }
        },
      });
    },

    //*****************************************//
    //   INSERT PER USER JOBKIND IN DATABASE   //
    //*****************************************//

    AddRecord() {
      // RECORD TO BE INSERT IN DATABASE

      if (this.record.EmployeeCode) {
        const record = {
          Job: JSON.stringify(this.toInsert),
          EmployeeCode: this.record.EmployeeCode,
        };
        // FUNCTION FOR INSERTING DATA IN DATABASE
        const InsertRecord = (data) =>
          CorrectionUserTable.Update(data)
            .then(() => {
              this.$emit(
                "UpdateJobkind",
                JSON.stringify(this.toInsert),
                this.records.index
              );
              this.$set(this.record, "Job", JSON.stringify(this.toInsert));
              this.addingDialog = false;
              this.$toast.success("Jobkind Successfully Added", objects.alert);
            })
            .catch(() => this.$toast.error("Failed to insert record", objects.alert));

        // CONDITION IF USER ADD EMPTY JOBKIND
        if (this.record.Job) {
          if (JSON.parse(this.record.Job).length !== this.toInsert.length)
            InsertRecord(record);
          else this.$toast.error("Please select jobkind", objects.alert);
        } else if (this.toInsert.length) InsertRecord(record);
        else this.$toast.error("Please select jobkind", objects.alert);
      } else {
        const record = {
          Job: JSON.stringify(this.toInsert),
          EmployeeCode: this.bulkSelectEmp
            .map((res) => `'${res.EmployeeCode}'`)
            .join(","),
        };
        console.log(record);
        // FUNCTION FOR INSERTING DATA IN DATABASE
        const InsertRecord = (data) =>
          CorrectionUserTable.BulkUpdateUsers(data)
            .then(() => {
              this.$emit(
                "UpdateJobkind",
                JSON.stringify(this.toInsert),
                this.records.index
              );
              this.$set(this.record, "Job", JSON.stringify(this.toInsert));
              this.addingDialog = false;
              this.$toast.success("Jobkind Successfully Added", objects.alert);
            })
            .catch(() => this.$toast.error("Failed to insert record", objects.alert));

        // CONDITION IF USER ADD EMPTY JOBKIND
        if (this.record.Job) {
          if (JSON.parse(this.record.Job).length !== this.toInsert.length)
            InsertRecord(record);
          else this.$toast.error("Please select jobkind", objects.alert);
        } else if (this.toInsert.length) InsertRecord(record);
        else this.$toast.error("Please select jobkind", objects.alert);
      }
      // console.log(record, this.records, this.bulkSelectEmp)
    },

    //**********************//
    //   OPEN DIALOG MODAL  //
    //**********************//

    OpenDialog() {
      this.dialog = !this.dialog;
    },

    //******************************************//
    //   PASS RECORD AFTER USER ONCLICK EVENT   //
    //******************************************//

    PassRecord() {
      this.record = { ...(this.records.record || "") };
      this.toInsert = this.record.Job ? JSON.parse(this.record.Job) : [];
    },

    //**********************//
    //   GET ALL JOBKIND    //
    //**********************//

    GetJobKind() {
      JobKindTable.Get().then((res) => (this.jobKind = res.data));
    },
  },
};
</script>

<style lang="scss">
.jobkind {
  background-color: #ffffff;
  font-weight: bold;
  &:hover {
    background-color: silver;
  }
}
</style>
