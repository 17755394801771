<template>
  <div>
    <!-- LIST OF PER EMPLOYEE JOBKIND -->
    <v-dialog v-model="dialog" max-width="600px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">InquiryKind</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            List of {{ EmployeeFirstName }} InquiryKind
          </p>
        </div>
        <v-divider />
        <!-- RECORDS -->
        <v-row v-if="record.Inquiry" style="height: 500px" v-scrollbar>
          <v-col
            >
             <v-text-field
            v-model="searchInquiryCode" 
            prepend-inner-icon="mdi-magnify"
            label="Search Inquiry Code"
            outlined 
            dense 
            block 
            />
            <v-list v-if="searchInListOfInquiry.length">
              <v-list-item-group color="#df6464" width="100%">
                <v-list-item
                  v-for="(val, index) in searchInListOfInquiry"
                  :key="index"
                >
                  <v-list-item-content @dblclick="DeleteJobKind(val.Code)">
                    <v-list-item-title>
                      {{ val.Code }} - ( {{ val.EnglishName }} /
                      {{ val.JapaneseName }} )
                    </v-list-item-title>
                    <v-divider class="mt-2" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group> </v-list
          >
            <v-list v-else>
              <center><b style="color:red; float: center">NO RECORD FOUND!</b></center>
            </v-list>
          </v-col>
        </v-row>
        <!-- FOR NO RECORD -->
        <v-row v-else>
          <v-col
            class="text-center"
            style="color: red; font-weight: bold; font-size: 20px"
            v-html="'No Record Found'"
          />
        </v-row>
        <v-divider />
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to delete Inquirykind</span
        >
        <!-- ACTION -->
        <v-btn
          @click="addingDialog = !addingDialog"
          style="color: white"
          color="#20b2aa"
          block
          >Add Inquirykind <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card>
    </v-dialog>

    <!-- ALL THE LIST OF SYSTEM JOBKIND && ADDING OF JOBKIND -->
    <v-dialog
      v-model="addingDialog"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-row>
          <v-col>
            <!-- HEADER -->
            <div class="text-center section">
              <h2 class="h2">Select Jobkind</h2>
            </div></v-col
          >
        </v-row>
        <!-- SEARCH JOBKIND -->
        <v-row class="mb-n5">
          <v-col>
            <v-text-field
              v-model="searchCode"
              label="Inquiry Code"
              color="red"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="searchKind"
              label="JobKind"
              color="red"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <div v-if="filterInquiryKind.length" style="height: 500px" v-scrollbar>
          <v-row class="ma-n3">
            <v-list>
              <v-list-item-group color="#df6464" style="">
                <v-list-item
                  v-for="(val, index) in filterInquiryKind"
                  :key="index"
                  style="width: 600px"
                >
                  <v-list-item-content @dblclick="ConfirmInformation(val.Code)">
                    <v-list-item-title>
                      {{ val.Code }} - ( {{ val.EnglishName }} /
                      {{ val.JapaneseName }} )
                    </v-list-item-title>
                    <v-divider class="mt-2" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </div>
        <!-- FOR NO RECORD -->
        <div v-else>
          <v-row>
            <v-col
              class="text-center"
              colspan="9"
              v-html="'No Record Found'"
              style="color: red; font-weight: bold; font-size: 20px"
            />
          </v-row>
        </div>
        <v-divider></v-divider>
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to add Inquirykind</span
        >
        <!-- ACTION -->
        <v-btn
          @click="AddRecord()"
          class="mb-n2 mt-3"
          color="#20b2aa"
          style="color: white"
          block
          >Insert</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InquiryKindTable from "../../../functions/httpRequest/ExternalConnection/InquiryKindTable";
import CorrectionUserTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import objects from "../../../functions/alert";

export default {
  data() {
    return {
      dialog: false,
      addingDialog: false,
      searchCode: "",
      searchKind: "",
      searchInquiryCode:"",
      record: {},
      search: {},
      inquiryKind: [],
      toInsert: [],
    };
  },
  props: {
    records: {
      type: Object,
    },
  },
  created() {
    this.GetJobKind();
  },

  computed: {
    //****************************//
    //  GET EMPLOYEE FIRST NAME   //
    //****************************//
searchInListOfInquiry(){
  
   if (this.searchInquiryCode) {
        //if search ay may katulad sa list?
        return this.userInquirykind.filter((element) => {
          return element.Code.toUpperCase().includes(this.searchInquiryCode.toUpperCase()) || 
          element.EnglishName.toUpperCase().includes(this.searchInquiryCode.toUpperCase()); //finifilter nya yung element na may katulad na letter sa search
        });
      } else {
        return this.userInquirykind; //kung mag false ang condition
      }
    },
    EmployeeFirstName() {
      // CHECK IF KEY IS EXIST
      let fullname = Object.keys(this.record).length
        ? this.record.EmployeeName.toLowerCase().split(" ").splice(0, 1)
        : [];
      // UPPERCASE FIRST LETTER EVERY WORD
      for (let x in fullname)
        fullname[x] = `${fullname[x].charAt(0).toUpperCase()}${fullname[
          x
        ].substring(1)}`;

      return `${fullname.join(" ")}'s`;
    },

    //******************************//
    //  SEARCH AND FILTER JOBKIND   //
    //******************************//

    filterInquiryKind() {
      // CHECK IF USER SEARCH SOMETHING
      if (this.searchCode || this.searchKind) {
        let type;
        let search;
        if (this.searchCode) {
          type = "Code";
          search = "searchCode";
        } else {
          type = "JapaneseName";
          search = "searchKind";
        }

        return this.inquiryKind.filter((el) =>
          el[type].toLowerCase().includes(this[search].toLowerCase())
        );
      } else return this.inquiryKind; //RETURN ALL DATA
    },

    //******************************//
    //  GET ALL PER USER JOBKIND    //
    //******************************//

    userInquirykind() {
      const userinquirykind = this.record.Inquiry
        ? JSON.parse(this.record.Inquiry)
        : [];

      const filterinquiryKind = this.inquiryKind
        .filter((el) => {
          const check = userinquirykind.indexOf(el.Code);
          return check === -1 ? false : true;
        })
        .map((el) => el);
      return filterinquiryKind;
    },
  },
  methods: {
    //******************************//
    //   DELETE PER USER INQUIRYKIND    //
    //******************************//

    DeleteJobKind(val) {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to delete inquirykind",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // CONVERT JOB TO STRING
            this.record.Inquiry = JSON.stringify(
              JSON.parse(this.record.Inquiry).filter((code) =>
                code.indexOf(val)
              )
            );
            // CHECK IF TOINSERT IS EMPTY
            this.toInsert = this.toInsert.filter((code) => code.indexOf(val));
            if (this.record.Inquiry == "[]") this.record.Inquiry = null;

            // RECORD TO BE PASS IN DATABASE
            const record = {
              Inquiry: this.record.Inquiry == "[]" ? null : this.record.Inquiry,
              EmployeeCode: this.record.EmployeeCode,
            };
            // UPDATE DATABASE
            CorrectionUserTable.Update(record).then(() => {
              this.$emit(
                "UpdateInquirykind",
                record.Inquiry,
                this.records.index
              ); //UPDATE USERACCOUNT JOB
              this.$toast.success("Deleted Successfully", objects.alert);
            });
          }
        },
      });
    },

    //******************************//
    //   ADDING PER USER INQUIRYKIND    //
    //******************************//

    ConfirmInformation(Code) {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to add Inquirykind",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // CHECK IF INQUIRYKIND IS ALREADY EXIST
            if (this.toInsert.indexOf(Code) != -1)
              return this.$toast.error(
                "Inquirykind already exist",
                objects.alert
              );
            this.toInsert.push(Code);
            this.$toast.info("Added", objects.alert);
          }
        },
      });
    },

    //*****************************************//
    //   INSERT PER USER INQUIRYKIND IN DATABASE   //
    //*****************************************//

    AddRecord() {
      // RECORD TO BE INSERT IN DATABASE
      const record = {
        Inquiry: JSON.stringify(this.toInsert),
        EmployeeCode: this.record.EmployeeCode,
      };
      // FUNCTION FOR INSERTING DATA IN DATABASE
      const InsertRecord = (data) =>
        CorrectionUserTable.Update(data)
          .then(() => {
            this.$emit(
              "UpdateInquirykind",
              JSON.stringify(this.toInsert),
              this.records.index
            );
            this.$set(this.record, "Inquiry", JSON.stringify(this.toInsert));
            this.addingDialog = false;
            this.$toast.success(
              "Inquirykind Successfully Added",
              objects.alert
            );
          })
          .catch(() =>
            this.$toast.error("Failed to insert record", objects.alert)
          );

      // CONDITION IF USER ADD EMPTY JOBKIND
      if (this.record.Inquiry) {
        if (JSON.parse(this.record.Inquiry).length !== this.toInsert.length)
          InsertRecord(record);
        else this.$toast.error("Please select inquirykind", objects.alert);
      } else if (this.toInsert.length) InsertRecord(record);
      else this.$toast.error("Please select inquirykind", objects.alert);
    },

    //**********************//
    //   OPEN DIALOG MODAL  //
    //**********************//

    OpenDialog() {
      this.dialog = !this.dialog;
    },

    //******************************************//
    //   PASS RECORD AFTER USER ONCLICK EVENT   //
    //******************************************//

    PassRecord() {
      this.record = { ...(this.records.record || "") };
      this.toInsert = this.record.Inquiry
        ? JSON.parse(this.record.Inquiry)
        : [];
    },

    //**********************//
    //   GET ALL JOBKIND    //
    //**********************//

    GetJobKind() {
      InquiryKindTable.Get().then((res) => (this.inquiryKind = res.data));
    },
  },
};
</script>

<style lang="scss">
.jobkind {
  background-color: #ffffff;
  font-weight: bold;
  &:hover {
    background-color: silver;
  }
}
</style>