<template>
  <v-container class="CategoryCard" fluid v-scrollbar>
    <v-tabs v-model="tab" color="#df6464" centered icons-and-text>
      <v-tab href="#tab-1">
        Users
        <v-icon>mdi-account-multiple</v-icon>
      </v-tab>
      <v-tab href="#tab-2">
        Template
        <v-icon>mdi-account-details</v-icon>
      </v-tab>
      <v-tab href="#tab-3">
        Category
        <v-icon>mdi-archive-outline</v-icon>
      </v-tab>
      <v-tab href="#tab-4">
        Manual
        <v-icon>mdi-book-open</v-icon>
      </v-tab>

      <!------------------------------------------------------
       **        MASTER MAINTENANCE COMPONENT               **
       **  CAN BE SEEN IN CORRECTION REQUEST MASTER FOLDER  **
       ------------------------------------------------------>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-divider class="mt-3"></v-divider>
          <UserAccountComponent />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-divider class="mt-3"></v-divider>
          <TemplateComponent />
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-divider class="mt-3"></v-divider>
          <CategoryComponent />
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-divider class="mt-3"></v-divider>
          <ManualComponent />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import store from "@/store";
import UserAccountComponent from "../components/CorrectionRequestMaster/Users/UserAccount.vue";
import CategoryComponent from "../components/CorrectionRequestMaster/Category/Category.vue";
import TemplateComponent from "../components/CorrectionRequestMaster/Template/Template.vue";
import ManualComponent from "../components/CorrectionRequestMaster/Manual/Manual.vue";

export default {
  data() {
    return {
      position: store.state.userinfo.Position,
      tab: null,
    };
  },
  components: {
    UserAccountComponent: UserAccountComponent,
    CategoryComponent: CategoryComponent,
    TemplateComponent: TemplateComponent,
    ManualComponent: ManualComponent,
  },
  mounted() {
    // if (!this.position.includes("Supervisor")) this.$router.push("/login");
  },
};
</script>
<style lang="scss">
.CategoryCard {
  max-height: calc(120vh - 210px);
}
</style>
