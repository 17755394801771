<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADERS -->
        <div class="text-center section">
          <h2 class="h2">Group</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
            List of {{ EmployeeFirstName }} recipient group
          </p>
        </div>
        <v-divider />
        <!-- RECORDS -->
        <v-row v-if="record.Group.length" style="height: 500px" v-scrollbar>
          <v-col>
            <v-text-field
              v-model="searchGroup"
              prepend-inner-icon="mdi-magnify"
              label="Search Group"
              outlined
              dense
              block
            />
            <v-list class="text-center" v-if="searchInListOfGroup.length">
              <v-list-item-group color="#df6464" width="100%">
                <v-list-item v-for="(val, index) in searchInListOfGroup" :key="index">
                  <v-list-item-content @dblclick="OpenInformation(val, index)">
                    <v-list-item-title style="font-weight: bold"
                      >{{ val.GroupName }}
                    </v-list-item-title>
                    <v-divider class="mt-2" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list v-else>
              <center><b style="color: red; float: center">NO RECORD FOUND!</b></center>
            </v-list>
          </v-col>
        </v-row>
        <!-- FOR NO RECORD -->
        <v-row v-else>
          <v-col
            class="text-center"
            style="color: red; font-weight: bold; font-size: 20px"
            >No Record Found</v-col
          >
        </v-row>
        <v-divider class="mb-3" />
        <span style="color: red; font-weight: bold; font-size: 11px"
          >Note: double click to see group information</span
        >
        <!-- ACTION -->
        <v-btn
          @click="$refs.addGroup.openDialog()"
          style="color: white"
          color="#20b2aa"
          block
          >Add Recipient Group <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="informationDialog"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <!-- HEADERS -->
        <div class="text-center section mb-2">
          <h2 class="h2">{{ info.GroupName }}</h2>
        </div>
        <v-divider class="mb-n3" />
        <v-container fluid>
          <v-row>
            <!-- TO -->
            <v-col v-if="info.To.length">
              <h4 class="text-center" v-html="'To'" />
              <!-- SWITCH -->
              <v-col v-if="checkCiInfoTo" cols="1" class="mr-2 mt-n3">
                <v-switch v-model="ciTo" label="CI" color="red" />
              </v-col>
              <!-- RECIPIENT -->
              <v-col>
                <!-- MANUAL -->
                <v-text-field
                  v-if="!ciTo"
                  v-model="add_to_recipient"
                  color="red"
                  label="Message Recipient (To)"
                  dense
                  outlined
                ></v-text-field>

                <!-- CUSTOMER INFORMATION -->
                <v-select
                  v-else
                  v-model="ci_to_value"
                  :items="checkCiInfoTo"
                  label="Customer Information (To)"
                  color="red"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col v-if="!ciTo">
                <v-text-field
                  v-model="add_to_email"
                  color="red"
                  label="E-mail Address (To)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-btn @click="addTo()" block class="mb-2" small color="success"
                >ADD <v-icon class="ml-1">mdi-card-account-mail</v-icon></v-btn
              >
              <v-simple-table fixed-header height="345px">
                <template v-slot:default>
                  <!-- THEAD -->
                  <thead>
                    <tr>
                      <th class="text-center">Action</th>
                      <th class="text-center">Recipient</th>
                      <th class="text-center">Email</th>
                    </tr>
                  </thead>
                  <!-- TBODY -->
                  <tbody>
                    <!-- ROW -->
                    <tr class="text-center" v-for="(val, i) in info.To" :key="i">
                      <td v-if="val">
                        <v-icon @click="editTo(val, info.ToEmail[i], i)"
                          >mdi-pencil</v-icon
                        >
                      </td>
                      <td v-else></td>
                      <td>{{ val }}</td>
                      <td>{{ info.ToEmail[i] }}</td>

                      <!-- DELETE ROW -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col class="text-center" v-else>
              <h4 v-html="'Cc'" />
              <span
                style="color: red; font-weight: bold; font-size: 20px"
                v-html="'No Record Found'"
              />
            </v-col>
            <v-divider vertical />
            <!-- CC -->
            <v-col v-if="info.Cc.length">
              <h4 class="text-center" v-html="'Cc'" />

              <v-col v-if="checkCiInfoTo" cols="1" class="mr-2 mt-n3">
                <v-switch v-model="ciCc" label="CI" color="red" />
              </v-col>
              <!-- RECIPIENT -->
              <v-col>
                <!-- MANUAL -->
                <v-text-field
                  v-if="!ciCc"
                  v-model="add_cc_recipient"
                  color="red"
                  label="Message Recipient (Cc)"
                  dense
                  outlined
                ></v-text-field>

                <!-- CUSTOMER INFORMATION -->
                <v-select
                  v-else
                  v-model="ci_cc_value"
                  :items="checkCiInfoTo"
                  label="Customer Information (Cc)"
                  color="red"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col v-if="!ciCc">
                <v-text-field
                  v-model="add_cc_email"
                  color="red"
                  label="E-mail Address (Cc)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-btn @click="addCc()" block class="mb-2" small color="success"
                >ADD <v-icon class="ml-1">mdi-card-account-mail</v-icon></v-btn
              >

              <v-simple-table fixed-header height="345px">
                <template v-slot:default>
                  <!-- THEAD -->
                  <thead>
                    <tr>
                      <th class="text-center">Action</th>

                      <th class="text-center">Recipient</th>
                      <th class="text-center">Email</th>
                    </tr>
                  </thead>
                  <!-- TBODY -->
                  <tbody>
                    <!-- ROW -->
                    <tr class="text-center" v-for="(val, i) in info.Cc" :key="i">
                      <td v-if="val">
                        <v-icon @click="editCc(val, info.CcEmail[i], i)"
                          >mdi-pencil</v-icon
                        >
                      </td>
                      <td v-else></td>
                      <td>{{ val }}</td>
                      <td>{{ info.CcEmail[i] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col class="text-center" v-else>
              <h4 v-html="'Cc'" />
              <span
                style="color: red; font-weight: bold; font-size: 20px"
                v-html="'No Record Found'"
              />
            </v-col>
          </v-row>
          <v-btn
            @click="DeleteGroup()"
            class="mb-n5 mt-2"
            style="color: white"
            color="#dc0e0e"
            block
            >Delete</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>

    <!-- EDIT DIALOG To-->
    <v-dialog v-model="editDialogTo" max-width="500px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">Update Recipient To</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey"></p>
        </div>
        <v-divider />
        <!-- FORM -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="editToRecipient"
              label="Message Recipient"
              color="red"
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="editToEmail"
              label="E-Mail Address"
              color="red"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <!-- ACTION -->
        <v-btn
          @click.native="UpdateRecordTo()"
          class="mt-n3"
          color="#2196f3"
          style="color: white"
          block
          >Update</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- EDIT DIALOG CC -->
    <v-dialog v-model="editDialogCc" max-width="500px" transition="dialog-transition">
      <v-card class="pa-5">
        <!-- HEADER -->
        <div class="text-center section">
          <h2 class="h2">Update Recipient Cc</h2>
          <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey"></p>
        </div>
        <v-divider />
        <!-- FORM -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="editCcRecipient"
              label="Message Recipient"
              color="red"
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="editCcEmail"
              label="E-Mail Address"
              color="red"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <!-- ACTION -->
        <v-btn
          @click.native="UpdateRecordCc()"
          class="mt-n3"
          color="#2196f3"
          style="color: white"
          block
          >Update</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- COMPONENT -->
    <AddGroup @UpdateGroup="UpdateGroup" ref="addGroup" />
  </div>
</template>

<script>
import others from "../../../../functions/alert";
import AddGroup from "./AddGroup.vue";
import CorrectionUser from "../../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";

export default {
  data() {
    return {
      add_cc_email: "",
      ci_cc_value: "",
      add_cc_recipient: "",
      add_to_email: "",
      add_to_recipient: "",
      dialog: false,
      ci_to_value: "",
      searchGroup: "",
      informationDialog: false,
      info: { Cc: [], To: [] },
      record: [],
      editDialogTo: false,
      editDialogCc: false,
      editIndex: -1,
      editToRecipient: "",
      editToEmail: "",
      editToIndex: null,
      editCcRecipient: "",
      editCcEmail: "",
      editCcIndex: null,
      ciTo: false,
      ciCc: false,
      ciToValue: "",
      ciCcValue: "",
      // form: { record: {} },
      toInsert: {
        info: {},
        record: [],
      },
      CustomerInfo: ["Planner", "Salesman", "First", "Middle"],
    };
  },
  watch: {
    ciTo(newValue) {
      if (newValue) this.ciToValue = "";
    },
    ciCc(newValue) {
      if (newValue) this.ciCcValue = "";
    },
  },
  components: {
    AddGroup: AddGroup,
  },
  props: {
    records: {
      type: Object,
    },
  },
  created() {
    this.record.Group = [];
  },
  computed: {
    checkCiInfoTo() {
      const record = this.toInsert.record;

      return this.CustomerInfo.filter((el) => {
        if (!record.length) return el;
        const check = record.findIndex((child) => child.To == el);
        return check >= 0 ? false : true;
      });
    },
    //****************************//
    //  GET EMPLOYEE FIRST NAME   //
    //****************************//
    searchInListOfGroup() {
      if (this.searchGroup) {
        //if search ay may katulad sa list?
        return this.record.Group.filter((element) => {
          return element.GroupName.toUpperCase().includes(this.searchGroup.toUpperCase());
        });
      } else {
        return this.record.Group; //kung mag false ang condition
      }
    },
    EmployeeFirstName() {
      // CHECK IF KEY IS EXIST
      let fullname =
        Object.keys(this.record).length > 1
          ? this.record.EmployeeName.toLowerCase().split(" ").splice(0, 1)
          : [];
      // UPPERCASE FIRST LETTER EVERY WORD
      for (let x in fullname)
        fullname[x] = `${fullname[x].charAt(0).toUpperCase()}${fullname[x].substring(1)}`;
      return `${fullname.join(" ")}'s`;
    },
  },
  methods: {
    addCc() {
      if (this.ci_cc_value) {
        this.info.Cc.push(this.ci_cc_value);
        this.info.CcEmail.push(this.ci_cc_value);
        this.record.Group[this.info.index].info.Cc = this.info.Cc.join(",");
        this.record.Group[this.info.index].info.CcEmail = this.info.CcEmail.join(",");
        const toUpdate = {
          Group: JSON.stringify(this.record.Group),
          EmployeeCode: this.records.record.EmployeeCode,
        };
        CorrectionUser.UpdateRecipient(toUpdate).then(() => {
          this.$toast.info("Update Successfully", others.alert);
          this.editDialogTo = false;
          this.ci_cc_value = "";
          this.add_cc_recipient = "";
          this.add_cc_email = "";
        });
      } else {
        this.info.Cc.push(this.add_cc_recipient);
        this.info.CcEmail.push(this.add_cc_email);
        this.record.Group[this.info.index].info.Cc = this.info.Cc.join(",");
        this.record.Group[this.info.index].info.CcEmail = this.info.CcEmail.join(",");
        const toUpdate = {
          Group: JSON.stringify(this.record.Group),
          EmployeeCode: this.records.record.EmployeeCode,
        };
        CorrectionUser.UpdateRecipient(toUpdate).then(() => {
          this.$toast.info("Update Successfully", others.alert);
          this.editDialogTo = false;
          this.ci_cc_value = "";
          this.add_cc_recipient = "";
          this.add_cc_email = "";
        });
      }
    },
    addTo() {
      if (this.ci_to_value) {
        this.info.To.push(this.ci_to_value);
        this.info.ToEmail.push(this.ci_to_value);
        this.record.Group[this.info.index].info.To = this.info.To.join(",");
        this.record.Group[this.info.index].info.ToEmail = this.info.ToEmail.join(",");
        const toUpdate = {
          Group: JSON.stringify(this.record.Group),
          EmployeeCode: this.records.record.EmployeeCode,
        };
        CorrectionUser.UpdateRecipient(toUpdate).then(() => {
          this.$toast.info("Update Successfully", others.alert);
          this.editDialogTo = false;
          this.ci_to_value = "";
          this.add_to_email = "";
          this.add_to_recipient = "";
        });
      } else {
        this.info.To.push(this.add_to_recipient);
        this.info.ToEmail.push(this.add_to_email);
        this.record.Group[this.info.index].info.To = this.info.To.join(",");
        this.record.Group[this.info.index].info.ToEmail = this.info.ToEmail.join(",");
        const toUpdate = {
          Group: JSON.stringify(this.record.Group),
          EmployeeCode: this.records.record.EmployeeCode,
        };
        CorrectionUser.UpdateRecipient(toUpdate).then(() => {
          this.$toast.info("Update Successfully", others.alert);
          this.editDialogTo = false;
          this.ci_to_value = "";
          this.add_to_email = "";
          this.add_to_recipient = "";
        });
      }
    },
    DeleteGroup() {
      this.$confirm({
        title: "Confirm",
        message: "Confirm to delete Group",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.$delete(this.record.Group, this.info.index);

            const toUpdate = {
              Group: JSON.stringify(this.record.Group),
              EmployeeCode: this.records.record.EmployeeCode,
            };

            CorrectionUser.Update(toUpdate).then(() => {
              this.$emit(
                "UpdateGroup",
                JSON.stringify(this.record.Group),
                this.records.index
              );
              this.$toast.success("Deleted Successfully", others.alert);
              this.informationDialog = !this.informationDialog;
            });
          }
        },
      });
    },

    //  EDIT TO
    editTo(To, Email, index) {
      this.editDialogTo = true;
      this.editToRecipient = To;
      this.editToEmail = Email;
      this.editToIndex = index;
    },

    //EDIT CC
    editCc(To, Email, index) {
      this.editDialogCc = true;
      this.editCcRecipient = To;
      this.editCcEmail = Email;
      this.editCcIndex = index;
    },

    //UPDATE TO
    UpdateRecordTo() {
      //Update on array
      this.info.To.splice(this.editToIndex, 1, this.editToRecipient);
      this.info.ToEmail.splice(this.editToIndex, 1, this.editToEmail);
      //Update on database
      this.record.Group[this.info.index].info.To = this.info.To.join(",");
      this.record.Group[this.info.index].info.ToEmail = this.info.ToEmail.join(",");
      const toUpdate = {
        Group: JSON.stringify(this.record.Group),
        EmployeeCode: this.records.record.EmployeeCode,
      };
      CorrectionUser.UpdateRecipient(toUpdate).then(() => {
        this.$toast.info("Update Successfully", others.alert);
        this.editDialogTo = false;
      });
    },

    //UPDATE CC
    UpdateRecordCc() {
      this.info.Cc.splice(this.editCcIndex, 1, this.editCcRecipient);
      this.info.CcEmail.splice(this.editCcIndex, 1, this.editCcEmail);
      this.$toast.info("Update Successfully", others.alert);
      this.editDialogCc = false;
    },

    //****************************//
    //    PASS SELECTED GROUP     //
    //****************************//
    OpenInformation(val, index) {
      for (let x in val.info) {
        if (val.info[x] == null) this.$set(this.info, x, []);
        else this.$set(this.info, x, val.info[x].split(","));
      }

      this.info.GroupName = val.GroupName;
      this.info.index = index;
      this.informationDialog = !this.informationDialog;
    },
    //************************************//
    //   UPDATE GROUP FROM EMIT EVENT     //
    //***********************************//

    UpdateGroup(obj) {
      const passObj = { ...obj };
      passObj.info = {};
      this.$set(this.record.Group, this.record.Group.length, passObj);
      const rec = this.record.Group[this.record.Group.length - 1];
      const key = ["To", "ToEmail", "Cc", "CcEmail"];
      key.forEach((el) => {
        if (
          (el == "Cc" || el == "CcEmail") &&
          !rec.record.filter((check) => Object.keys(check).includes("Cc")).length
        ) {
          rec.info[el] = null;
          rec.info[el] = null;
        } else if (
          (el == "To" || el == "ToEmail") &&
          !rec.record.filter((check) => Object.keys(check).includes("To")).length
        ) {
          rec.info[el] = null;
          rec.info[el] = null;
        } else {
          rec.info[el] = rec.record.map((key) => key[el]).join(",");

          rec.info[el].charAt(0) == "," ? (rec.info[el] = rec.info[el].substring(1)) : "";
        }
      });

      delete rec.record;
      const toUpdate = {
        Group: JSON.stringify(this.record.Group),
        EmployeeCode: this.records.record.EmployeeCode,
      };

      CorrectionUser.Update(toUpdate).then(() => {
        this.$emit("UpdateGroup", JSON.stringify(this.record.Group), this.records.index);
      });
    },
    //****************************//
    //        OPEN DIALOG         //
    //****************************//

    OpenDialog() {
      this.dialog = !this.dialog;
    },

    //******************************************//
    //   PASS RECORD AFTER USER ONCLICK EVENT   //
    //******************************************//

    PassRecord() {
      this.record = { ...(this.records.record || "") };
      this.record.Group = this.record.Group ? JSON.parse(this.record.Group) : [];
    },
  },
};
</script>
